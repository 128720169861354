@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600&family=Ubuntu:wght@300;400;500;700&display=swap');

.heading-c1{
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
}
.text-c1{  
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
}
.text-c2{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
.burger {
    position: relative;
    width: 35px;
    height: 25px;
    background: transparent;
    cursor: pointer;
    display: block;
  }
  
  .burger input {
    display: none;
  }
  
  .burger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: black;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  
  .burger span:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
  }
  
  .burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
  }
  
  .burger span:nth-of-type(3) {
    top: 100%;
    transform-origin: left center;
    transform: translateY(-100%);
  }
  .activeMenu {
    position: relative;
    width: 35px;
    height: 25px;
    background: transparent;
    cursor: pointer;
    display: block;
  }
  .activeMenu span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: black;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  .activeMenu span:nth-of-type(1) {
    transform: rotate(45deg);
    top: 0px;
    transform-origin: left center;
  }

  .activeMenu span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform: translatex(-100%);
    opacity: 0;
  }

  .activeMenu span:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 25px;
    transform-origin: left center;
  }


  .active{
    height: auto;
    top: 100%;
    padding: 2rem;
  }
  .active li{
    border-bottom: transparent;
    margin-block: .5rem;
  }
  .paste-button {
    position: relative;
    display: block;
    box-sizing: border-box;
  }
  
  
  .dropdown-content {
    height: auto;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    top: 100%;
    left: 0;
    margin-top: 5px;
  }
  .dropdown-content2 {
    height: 0px;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    top: 115%;
    left: 0;
  }
  
  .dropdown-content a {
    text-decoration: none;
    display: block;
    transition: 0.1s;
  }
  .activeItem{
    height: auto !important;
    display: block;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .navbar{
    height: 0;
    transition: .3s ease-in-out;
  }
  .open-navbar{
    height: 300px;
  }
  /*.paste-button .dropdown-triger:hover .dropdown-content, .dropdown-content:hover{
    height: auto;
  }*/
  .carousal-container{
    width: 100%;
    max-width: 1200px;
    margin-inline: auto;
    padding-bottom: 40px;
  }
  .slick-track{
    padding-block: 2rem;
  }
  .slick-slider .slick-arrow{
    display: none !important;
  }
  .slick-dots button{
    background-color: white !important;
    border-radius: 50%;
    margin: 0% !important;
    padding: 0% !important;
    width: 15px !important;
    height: 15px !important;
    box-shadow: inset 1px 1px 1px 1px #3b3b3b, 1px 1px 1px 0px #3b3b3b ;
  }
  .slick-dots .slick-active button{
    background-color: #3b3b3b !important;
  }
  .slick-dots button::before{
    color: transparent !important;
  }
  .slick-slider .slick-prev{
    left: 0;
  }
  .slick-slider .slick-next{
    right: 0;
  }
  .slick-slide{
    display: flex !important;
    justify-content: center;
  }
  .slick-slide > div{
    margin: 0 1rem;
    width: 20rem;
  }
  @media (min-width: 640px) { .slick-slide > div{
    margin: 0 .5rem;
  } 
  
}
  @media (min-width: 768px) { 
    .slick-slide > div{
    margin: 0 1rem;   
  } 
  .carousal-container{
    width: 100%;
  }
}

#containerBlogs{
  max-width: 1100px;
  margin-inline: auto;
  padding-block-start: 3rem;
}
.blog{
  display: flex;
  margin-block: 3rem;
  justify-content: center;
}
.blog:nth-child(even){
  flex-direction: row-reverse;
}
.blog .sideImg,.blog .sideCont{
  max-width: 50%;
  overflow: hidden;
}
.blog .sideImg img{
  width: 500px;
  height: 400px;
  transition: .5s ease-in-out;
}
.blog .sideImg img:hover{
  transform: scale(1.2);
}
.blog .sideCont>div{
  padding: 2rem;
  margin-inline: 10px;
}
.blog .sideCont>div p{
  color: rgb(126 126 126);;
}
.blog .sideCont>div .btnReadMore{
  cursor: pointer;
  display: inline-block;
  outline: none;
  text-decoration: none;
  padding: 10px 20px;
  margin: 10px;
  font-family: var(--fontMplus);
  border-radius: 20px;
  background: linear-gradient(to left, #3264f5 51%, #7202bb 100%);
  color: white;
}
@media screen and (max-width:700px) {
  .blog:nth-child(odd){
      flex-direction: column;
  }
  .blog:nth-child(even){
    flex-direction: column;
  }
  .blog .sideImg img{
      width: 400px;
      height: 300px;
  }
  .blog .sideImg,.blog .sideCont{
      max-width: 400px;
      margin-inline: auto;
  }
}
@media screen and (max-width:500px) {
  .blog .sideImg img{
      width: 100%;
      height: 300px;
  }
  .blog .sideImg{
    aspect-ratio: 1/1;
  }
  .blog .sideImg,.blog .sideCont{
      max-width: 100%;
      margin-inline: auto;
  }
  .blog .sideCont>div{
    padding: .5rem;
    margin-inline: 0;
  }
}
@media screen and (max-width:200px) {
  .blog .sideImg img{
      width: 100%;
  }
  .blog .sideImg{
      max-width: 100%;
      margin-inline: auto;
  }
  .blog .sideCont{
    max-width: 100%;
    margin-inline: auto;
}
}


.collection_scrollbar{
  display: grid !important;
  grid-auto-flow: column;
  scroll-behavior: auto;
  gap: 1.2rem;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}
.collection_scrollbar div{
  scroll-snap-align: start;
}

.collection_scrollbar::-webkit-scrollbar{
  display: none;
}